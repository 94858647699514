import { Injectable } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { DEFAULT_CURRENCY, LANGUAGE_CODE_DE } from '@core/constants/defaults';
import { LanguageService } from '@core/services/language.service';
import { NavigationService } from '@core/services/navigation/navigation.service';
import {
  PaymentAccountsModel,
  PaymentSortCodeModel,
} from '@models/account.model';
import { PaymentSecSignSessionType } from '@models/auth-session.model';
import {
  ATTORNEY_CODE,
  ATTORNEY_CODE_APPROVAL_REQUIRED,
  ATTORNEY_CODE_ONLY_INFO,
  CountryModel,
  PaymentFormModel,
  PaymentOperation,
  PaymentScheduleType,
  PaymentType,
  StandingOrdersModel,
} from '@models/payment.model';
import { TemplateModel } from '@models/template.model';
import {
  convertShortLocalDate,
  formatAmount,
  removeSpaceCharacters,
} from '@modules/payments/PaymentUtils';
import { Store } from '@ngrx/store';
import { AuthActions } from '@store/auth-store';
import { PaymentActions, PaymentSelectors } from '@store/payment-store';
import { noop } from 'lodash';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, delay, repeat } from 'rxjs/operators';
import { TwoFaConfirmOperationModalComponent } from './components/2fa-confirm-operation-modal/2fa-confirm-operation-modal.component';

@Injectable({
  providedIn: 'root',
})
export class PaymentUtilService {
  paymentData!: PaymentFormModel;
  debtorAccount!: PaymentAccountsModel;
  sortCodeData!: PaymentSortCodeModel;
  isNonSepa = false;
  disablePaymentAccountSelector = new BehaviorSubject<boolean>(false);
  disablePaymentAccountSelector$: any =
    this.disablePaymentAccountSelector.asObservable();
  detectNewPaymentTabSelection = new BehaviorSubject<boolean>(true);
  detectNewPaymentTabSelection$: any =
    this.detectNewPaymentTabSelection.asObservable();
  private countries: CountryModel[] = [];
  private modalCancel = new Subject<boolean>();
  private noPaymentAccounts = new Subject<boolean>();

  modalCancel$ = this.modalCancel.asObservable();
  noPaymentAccounts$ = this.noPaymentAccounts.asObservable();

  constructor(
    private store$: Store,
    private dialog: MatDialog,
    private router: Router,
    private languageService: LanguageService,
    private navigationService: NavigationService
  ) {
    this.store$
      .select(PaymentSelectors.getSelectedPaymentAccount)
      .subscribe((paymentAccount: PaymentAccountsModel) => {
        this.debtorAccount = paymentAccount;
      });
    this.store$
      .select(PaymentSelectors.getSortCodeData)
      .subscribe((sortCodeData: PaymentSortCodeModel) => {
        this.sortCodeData = sortCodeData;
      });
    this.store$
      .select(PaymentSelectors.selectCreatedPayment)
      .subscribe((paymentData: PaymentFormModel) => {
        if (paymentData) {
          this.paymentData = paymentData;
          this.isNonSepa = !!this.paymentData.country;
        }
      });
    this.store$
      .select(PaymentSelectors.selectCountries)
      .subscribe((countries: CountryModel[]) => {
        this.countries = countries;
      });
  }

  isUserHasPermissionOnlyToCreateApprovalPayment(): boolean {
    return sessionStorage.getItem('attorneyCode') === ATTORNEY_CODE.JOINTPOA;
  }

  mapPaymentData() {
    return this.createPaymentData();
  }

  getPaymentType() {
    return this.isNonSepa ? PaymentType.NON_EURO : PaymentType.SEPA;
  }

  createPaymentData() {
    return {
      debtorAccount: this.createDebtorAccountInformation(),
      schedule: this.createScheduleData(),
      paymentType: this.getPaymentType(),
      creditTransferTransactionInformation: [
        this.createCreditTransferTransactionInformation(),
      ],
      additions: {},
    };
  }

  createCreditTransferTransactionInformation() {
    const creditTransferTransactionInformation = {
      creditor: this.createCreditor(),
      creditorBank: this.createCreditorBank(),
      creditorAccount: this.createCreditorAccount(),
      instructedAmount: this.createInstructedAmount(),
      remittanceInformation: this.paymentData.description,
    };
    return !this.isNonSepa
      ? creditTransferTransactionInformation
      : {
          ...creditTransferTransactionInformation,
          additions: this.createAdditions(),
        };
  }

  createCreditor() {
    const creditorName = { name: this.paymentData.recipient };
    return !this.isNonSepa
      ? creditorName
      : {
          ...creditorName,
          postalAddress: {
            country: this.paymentData.country.code,
          },
        };
  }

  createCreditorAccount() {
    const identification = {
      identification: {
        identification:
          this.sortCodeData && this.sortCodeData.sortCodeIban
            ? removeSpaceCharacters(this.sortCodeData.sortCodeIban)
            : removeSpaceCharacters(this.paymentData.accountNumber),
      },
    };
    return this.isNonSepa
      ? identification
      : {
          ...identification,
          schemeName: 'IBAN',
        };
  }

  createDebtorAccountInformation() {
    return {
      identification: this.createDebtorIdentificationInformation(),
      additions: {
        accountId: this.debtorAccount.id,
      },
    };
  }

  createCreditorBank() {
    return {
      bic:
        this.sortCodeData && this.sortCodeData.sortCodeBic
          ? this.sortCodeData.sortCodeBic
          : this.paymentData.bic,
      name:
        this.sortCodeData && this.sortCodeData.sortCodeBank
          ? this.sortCodeData.sortCodeBank
          : this.paymentData.bankName,
    };
  }

  createAdditions() {
    return {
      chargeType: this.paymentData.charges,
    };
  }

  createInstructedAmount() {
    return {
      amount: formatAmount(this.paymentData.amount),
      currencyCode: this.paymentData.currencyCode,
    };
  }

  createDebtorIdentificationInformation() {
    const identification = {
      identification: removeSpaceCharacters(this.debtorAccount.IBAN),
    };
    return this.isNonSepa
      ? identification
      : {
          ...identification,
          schemeName: 'IBAN',
        };
  }

  createScheduleData() {
    if (
      this.paymentData.paymentScheduledType ==
      PaymentScheduleType.STANDING_ORDER
    ) {
      return {
        transferFrequency: this.paymentData.interval,
        startDate: convertShortLocalDate(this.paymentData.firstExecution),
        endDate: convertShortLocalDate(this.paymentData.lastExecution),
      };
    } else if (
      this.paymentData.paymentScheduledType ==
      PaymentScheduleType.SCHEDULED_TRANSFER
    ) {
      return {
        transferFrequency: 'ONCE',
        startDate: convertShortLocalDate(this.paymentData.onceExecution),
      };
    }
    return null;
  }

  pollSecSignResponse(serviceResult: Observable<any>): void {
    const subscription = serviceResult
      .pipe(
        delay(1000),
        catchError((error) => {
          subscription.unsubscribe();
          this.store$.dispatch(
            new PaymentActions.SetSecSignStatus(
              PaymentSecSignSessionType.CANCELLED
            )
          );
          return throwError(error);
        }),
        repeat()
      )
      .subscribe((resp: any) => {
        if (resp.status !== PaymentSecSignSessionType.ACCEPTED) {
          this.store$.dispatch(
            new PaymentActions.SetSecSignStatus(resp.status)
          );
          subscription.unsubscribe();
        }
      });
  }

  proceedOperation(
    serviceResult: Observable<any>,
    paymentOperation: PaymentOperation
  ) {
    this.pollSecSignResponse(serviceResult);
    const dialogRef: MatDialogRef<TwoFaConfirmOperationModalComponent> =
      this.authenticateWithSecSign(paymentOperation);
    this.store$
      .select(PaymentSelectors.selectSecSignStatus)
      .subscribe((response) => {
        if (paymentOperation === PaymentOperation.DELETE_STANDING_ORDER) {
          this.store$.dispatch(
            new PaymentActions.SetStandingOrderDeleteActionResult(response)
          );
        } else if (
          paymentOperation === PaymentOperation.APPROVE_PAYMENT &&
          response !== PaymentSecSignSessionType.ACCEPTED
        ) {
          this.store$.dispatch(
            new PaymentActions.CompletePaymentApproveAction()
          );
        }
        if (
          response === PaymentSecSignSessionType.REJECTED ||
          response === PaymentSecSignSessionType.CANCELLED
        ) {
          this.finishSecSignAuthentication(dialogRef, response);
        } else if (response === PaymentSecSignSessionType.PROCESSED) {
          if (paymentOperation === PaymentOperation.DELETE_STANDING_ORDER) {
            dialogRef.close(response);
          } else if (dialogRef.componentInstance) {
            dialogRef.componentInstance.twoFactorStatus = response;
          }
        }
        if (response !== PaymentSecSignSessionType.ACCEPTED) {
          sessionStorage.removeItem('auth_session');
        }
      });
  }

  finishSecSignAuthentication(
    dialogRef: MatDialogRef<TwoFaConfirmOperationModalComponent>,
    response: any
  ): void {
    if (dialogRef.componentInstance) {
      dialogRef.componentInstance.twoFactorStatus = response;
    }
    dialogRef.close();
  }

  authenticateWithSecSign(
    paymentOperation: PaymentOperation
  ): MatDialogRef<TwoFaConfirmOperationModalComponent> {
    const dialogRef = this.dialog.open(TwoFaConfirmOperationModalComponent);
    dialogRef.addPanelClass('generic-modal');
    dialogRef.disableClose = true;
    dialogRef.componentInstance.paymentOperation = paymentOperation;
    if (
      paymentOperation === PaymentOperation.NEW_PAYMENT &&
      this.isUserHasPermissionOnlyToCreateApprovalPayment()
    ) {
      dialogRef.componentInstance.operationPendingTitleI18nKey =
        'payment.second.factor.modal.header';
      dialogRef.componentInstance.operationConfirmedTitleI18nKey =
        'payment.approval.second.factor.confirmation.modal.header';
      dialogRef.componentInstance.operationPendingTextI18nKey =
        'payment.second.factor.modal.text';
      dialogRef.componentInstance.operationConfirmedTextI18nKey =
        'payment.approval.second.factor.confirmation.modal.text';
    } else if (
      paymentOperation === PaymentOperation.APPROVE_PAYMENT ||
      paymentOperation === PaymentOperation.CHANGE_STANDING_ORDER ||
      paymentOperation === PaymentOperation.NEW_PAYMENT
    ) {
      dialogRef.componentInstance.operationPendingTitleI18nKey =
        'payment.second.factor.modal.header';
      dialogRef.componentInstance.operationConfirmedTitleI18nKey =
        'payment.second.factor.confirmation.modal.header';
      dialogRef.componentInstance.operationPendingTextI18nKey =
        'payment.second.factor.modal.text';
      dialogRef.componentInstance.operationConfirmedTextI18nKey =
        'payment.second.factor.confirmation.modal.text';
    } else if (paymentOperation === PaymentOperation.DELETE_STANDING_ORDER) {
      dialogRef.componentInstance.operationPendingTitleI18nKey =
        'payment.second.factor.modal.deletion.header';
      dialogRef.componentInstance.operationConfirmedTitleI18nKey = '';
      dialogRef.componentInstance.operationPendingTextI18nKey =
        'payment.second.factor.modal.deletion.text';
      dialogRef.componentInstance.operationConfirmedTextI18nKey = '';
    }

    dialogRef.componentInstance.confirmationEmitter.subscribe((response) => {
      if (response) {
        dialogRef.close();
        this.store$.dispatch(new PaymentActions.SetCreatedPayment({}));
        this.router.navigated = false;
        const url =
          paymentOperation === PaymentOperation.APPROVE_PAYMENT
            ? '/payments/approvals'
            : '/payments/new';
        this.router
          .navigate([url])
          ?.then()
          .catch(() => noop());
      } else {
        dialogRef.close();
        this.store$.dispatch(
          new AuthActions.CancelAuthSession(
            sessionStorage.getItem('auth_session')
          )
        );
        this.store$.dispatch(
          new PaymentActions.SetSecSignStatus(
            PaymentSecSignSessionType.NO_STATE
          )
        );
      }
    });

    return dialogRef;
  }

  getAvailableText(value: string): string {
    value = value.replace(/[^\u0020-\u007F]/g, '');
    value = value.replace(/\\u00..|\\n|\\f|\\|'/g, '');
    value = value.replace(/<[^>]*>/g, '');
    return value;
  }

  mapStandingOrderDataToForm(
    model: StandingOrdersModel,
    isStandingOrderChange: boolean
  ) {
    const countryCode =
      model.creditTransferTransactionInformation[0].creditor.postalAddress
        .country;
    let formData: any = {
      recipient: model.creditTransferTransactionInformation[0].creditor.name,
      country:
        countryCode && countryCode.length > 0
          ? this.getCountryByCode(countryCode)
          : countryCode,
      accountNumber:
        model.creditTransferTransactionInformation[0].creditorAccount
          .identification.identification,
      bic: model.creditTransferTransactionInformation[0].creditorBank.bic,
      currencyCode:
        model.creditTransferTransactionInformation[0].instructedAmount
          .currencyCode,
      description:
        model.creditTransferTransactionInformation[0].remittanceInformation,
      charges:
        model.creditTransferTransactionInformation[0].additions.chargeType,
      paymentScheduledType: PaymentScheduleType.TRANSFER,
    };

    if (isStandingOrderChange) {
      formData = {
        ...formData,
        amount:
          model.creditTransferTransactionInformation[0].instructedAmount.amount.toString(),
        interval: model.schedule.transferFrequency,
        onceExecution: model.schedule.startDate ?? null,
        firstExecution: model.schedule.startDate ?? null,
        nextExecution: model.schedule.nextExecutionDate ?? null,
        lastExecution: model.schedule.endDate ?? null,
        paymentScheduledType:
          model.schedule.transferFrequency === 'ONCE'
            ? PaymentScheduleType.SCHEDULED_TRANSFER
            : PaymentScheduleType.STANDING_ORDER,
      };
    }
    return formData;
  }

  getCountryByCode(countryCode: string) {
    return this.countries.filter(
      (country: CountryModel) => country.code === countryCode
    )[0];
  }

  mapTemplateDataToForm(templateData: TemplateModel) {
    let formData: any = {
      paymentScheduledType: PaymentScheduleType.TRANSFER,
      description: templateData.description,
      accountNumber: templateData.iban ?? templateData.recipientAccountCode,
      currencyCode: templateData.ccyIsoCode ?? DEFAULT_CURRENCY,
      recipient: templateData.recipient,
      bic: templateData.bic,
    };
    if (templateData.paymentType === PaymentType.NON_EURO) {
      const countryCode = templateData.recipientCountryIsoCode;
      formData = {
        ...formData,
        country:
          countryCode && countryCode.length > 0
            ? this.getCountryByCode(countryCode)
            : countryCode,
      };
    }
    return formData;
  }

  isUserHasRightOnlyGetInfo() {
    return sessionStorage.getItem('attorneyCode') === ATTORNEY_CODE_ONLY_INFO;
  }

  isUserHasNotRightForActions(
    selectedPaymentAccount: PaymentAccountsModel | null
  ) {
    return (
      sessionStorage.getItem('attorneyCode') === ATTORNEY_CODE_ONLY_INFO ||
      sessionStorage.getItem('attorneyCode') ===
        ATTORNEY_CODE_APPROVAL_REQUIRED ||
      selectedPaymentAccount?.additions.transferLimit == 0
    );
  }

  checkZeroValue(value: any) {
    const retValue = parseFloat(value.toString().replace(',', '.'));
    const amount_not_allowed_pattern = '(^[.,].*$)';
    if (value.match(amount_not_allowed_pattern)) {
      return this.languageService.getCurrentLanguage() === LANGUAGE_CODE_DE
        ? retValue.toFixed(2).toString().replace('.', ',')
        : retValue.toFixed(2);
    } else if (retValue == 0) {
      return '';
    }
    return null;
  }

  getLocalizedAmount(value: any) {
    return this.languageService.getCurrentLanguage() === LANGUAGE_CODE_DE
      ? value.toString().replace('.', ',')
      : value;
  }

  public reRoutePage(reRoute: boolean) {
    const mastNum = sessionStorage.getItem('SetMasterNumberRequestAction');
    if (reRoute) {
      if (mastNum) {
        this.navigationService.setSelectedClient(mastNum).subscribe(() => {
          window.location.reload();
        });
        sessionStorage.removeItem('SetMasterNumberRequestAction');
      }
    } else {
      this.cancelModal(true);
    }
  }

  private cancelModal(cancel: boolean) {
    this.modalCancel.next(cancel);
  }

  public anyPaymentAccounts(empty: boolean) {
    this.noPaymentAccounts.next(empty);
  }
}
