import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ClientsDto,
  SelectedClientDto,
} from '@models/entitlements/client.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private http: HttpClient) {}

  clients$ = new BehaviorSubject<ClientsDto | null>(null);

  fetchClients(force: Boolean = false): Observable<ClientsDto> {
    if (!force && this.clients$.value) {
      return of(this.clients$.value);
    }

    return this.http
      .get<ClientsDto>('/api/v1-legacy/entitlements/clients')
      .pipe(
        tap((result) => {
          this.clients$.next(result);
        })
      );
  }

  setSelectedClient(masterNumber: string): Observable<void> {
    return this.http
      .put<void>(
        `/api/v1-legacy/entitlements/selected-client/${masterNumber}`,
        {}
      )
      .pipe(
        tap({
          next: (result) => {
            sessionStorage.setItem(
              'isSettingNewMasterNumberSuccessful',
              'true'
            );
          },
          error: (error) => {
            sessionStorage.setItem(
              'isSettingNewMasterNumberSuccessful',
              'false'
            );
          },
        })
      );
  }

  getSelectedClient(force: Boolean = false): Observable<SelectedClientDto> {
    if (!force && this.clients$.value) {
      return of(this.clients$.value.selectedClient);
    }
    return this.fetchClients().pipe(
      map((result) => {
        return result.selectedClient;
      })
    );
  }

  getSelectedLanguage(): Observable<any> {
    return this.http.get<any>('/api/v1-legacy/entitlements/selected-language');
  }

  setLanguage(language: string): Observable<any> {
    return this.http.put<any>(
      `/api/v1-legacy/entitlements/selected-language?language=${language}`,
      {
        language: language,
      }
    );
  }

  isDemoUser(): Observable<boolean> {
    return this.http.get<boolean>(`/public/api/v1/navigation/demo-user`);
  }

  getAnnouncements(): Observable<string[]> {
    return this.http.get<string[]>(`/public/api/v1/navigation/announcements`);
  }

  logMarketingCampaignClosed() {
    const body = {
      message: 'banner_close',
      category: 'marketing_content',
      session: '',
      plattform: 'Desktop',
      logLevel: 'INFO',
      clientTimestamp: new Date(),
    };
    return this.http
      .post<any>(`/public/api/v1/navigation/client-event`, body)
      .subscribe();
  }

  logMarketingCampaignClicked() {
    const body = {
      message: 'banner_open_link',
      category: 'marketing_content',
      session: '',
      plattform: 'Desktop',
      logLevel: 'INFO',
      clientTimestamp: new Date(),
    };
    return this.http
      .post(`/public/api/v1/navigation/client-event`, body)
      .subscribe();
  }
}
